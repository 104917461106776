<template>
  <div class="container" @click="doCopy()" style="height:100%">
    <mobile
      v-if="!isPC"
      class="mobile"
      :os="os"
      @getApkInfo="handleGetApkInfo"
      :ip="ip"
      :switchData="switchData"
      style="height:100%"
    ></mobile>

    <tips
      v-else-if="isPC && page_pc_url === 'https://sisqd.com/?dc=THD2'"
    ></tips>
    <pc
      v-else
      class="pc"
      :qr-img="QRImg"
      :os="os"
      @getApkInfo="handleGetApkInfo"
    ></pc>
    <!-- loading-->
    <div class="loading_box" v-if="isLoading && !isPC">
      <div style="height: 0.1rem"></div>
      <div class="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <h3>加载中...</h3>
    </div>
  </div>
</template>
<script>
import getApkInfo from "@/api/getApkInfo";
import getButtonlist from "@/api/getTemplate";
import userAgent from "@/utils/userAgent";
import QRCode from "qrcode";
import axios from "@/utils/request.js";
import mobile from "./components/mobile";
import pc from "./components/pc";
import tips from "./components/tips";
export default {
  components: {
    mobile,
    pc,
    tips,
  },
  data() {
    return {
      type: "",
      QRImg: "",
      os: "",
      param: "",
      cutInfo: {}, //替换url参数对象内<key> dc内容;
      ip: "",
      switchData: {},
      page_pc_url: "",
      downloadUrl: "",
      isLoading: false,
    };
  },

  computed: {
    isPC() {
      return this.os.isPc;
    },
  },
  created() {
    this.getPcUrl();
    this.os = userAgent();
    this.functionParam();
    this.getButtonlist();
    // this.getIp();
  },
  mounted() {
    this.getQRImg();
  },
  methods: {
    getPcUrl() {
      this.page_pc_url = window.location.href;
    },
    //1.判断进来的url地址 替换到需要手动设置的参数 如cd
    functionParam() {
      this.param =
        JSON.stringify(this.$route.query) === "{}"
          ? "{}"
          : JSON.stringify(this.$route.query);
    },
    //2.获取按钮状态信息
    async getButtonlist() {
      this.isLoading = true;
      try {
        let param_btn_list = {
          appId: 7,
          channel: this.param,
          domain: window.location.protocol + "//" + window.location.host,
        };
        const res = await getButtonlist(param_btn_list);
        if (res.data.code === 200) {
          this.switchData = res.data.data;
        }
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
    },
    //3. 获取外网IP地址
    getIp() {
      axios.get("https://api.ipify.org/?format=json").then((res) => {
        this.ip = res.data.ip;
      });
    },
    //4. 点击下载按钮获取apk信息并下载
    async handleGetApkInfo(type) {
      let terminal;
      let packageType;
      if (type === "bate") {
        terminal = "ios";
        packageType = 3;
      } else if (type === "ios") {
        terminal = "ios";
        packageType = 1;
      } else {
        terminal = "android";
      }
      const param = {
        // eslint-disable-next-line no-undef
        appId: 7,
        ip: this.ip ? this.ip : "", //获取ip地址
        channel: JSON.stringify({
          ...JSON.parse(this.param),
          ...this.cutInfo,
        }),
        domain: window.location.protocol + "//" + window.location.host,
        terminal,
        packageType,
      };
      const res = await getApkInfo(param);
      if (res.data.code === 200) {
        window.location.href = res.data.data.link[0];
      }
    },
    //5.生成二维码
    getQRImg() {
      const color = this.type === "xingba" ? "#FFFFFF" : "";
      QRCode.toDataURL(window.location.href, {
        width: 240,
        margin: 1,
        maskPattern: 1,
          errorCorrectionLevel: 'H',
        scale: 1,
        color: {
          dark: color,
          light: "rgba()",
        },
      }).then((url) => {
        const regex = /code=/gi;
        if (url.indexOf("code=") != -1) {
          this.QRImg = url.replace(regex, "pc=");
        } else {
          this.QRImg = url;
        }
      });
    },
    // 6.剪贴板
    doCopy() {
      // eslint-disable-next-line no-undef
      this.$copyText(JSON.stringify({ ...JSON.parse(this.param) })).then(() => {
        console.log("复制剪贴板成功");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  /* loading */
  .loading_box {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    // top: 50%;
    // left: 50%;
    // margin-left: -75px;
    // margin-top: -75px;
    // width: 150px;
    // height: 150px;
    background: rgba(0, 0, 0, 0.7);
    z-index: 99999;
    border-radius: 0.4rem;
    display: flex;

    flex-direction: column;
    align-items: center;
    justify-content: center;

    h3 {
      padding-top: 0.2rem;
      font-size: 0.4rem;
      color: #fff;
    }
  }

  .lds-roller {
    display: inline-block;
    position: relative;
    width: 1.6rem;
    height: 1.6rem;
  }

  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 0.8rem 0.8rem;
  }

  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 0.14rem;
    height: 0.14rem;
    border-radius: 50%;
    background: #fff;
    margin: -0.08rem 0 0 -0.08rem;
  }

  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }

  .lds-roller div:nth-child(1):after {
    top: 1.26rem;
    left: 1.26rem;
  }

  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }

  .lds-roller div:nth-child(2):after {
    top: 1.36rem;
    left: 1.12rem;
  }

  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }

  .lds-roller div:nth-child(3):after {
    top: 1.42rem;
    left: 0.96rem;
  }

  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }

  .lds-roller div:nth-child(4):after {
    top: 1.44rem;
    left: 0.8rem;
  }

  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }

  .lds-roller div:nth-child(5):after {
    top: 1.42rem;
    left: 0.64rem;
  }

  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }

  .lds-roller div:nth-child(6):after {
    top: 1.36rem;
    left: 0.48rem;
  }

  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }

  .lds-roller div:nth-child(7):after {
    top: 1.26rem;
    left: 0.34rem;
  }

  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }

  .lds-roller div:nth-child(8):after {
    top: 1.12rem;
    left: 0.24rem;
  }

  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
  //overflow: hidden;
}
// @media screen and (min-width: 750px) {
//   .mobile {
//     display: none;
//   }
//   .pc {
//     overflow: hidden;
//   }
// }
// @media screen and (max-width: 750px) {
//   .pc {
//     display: none;
//   }
// }
</style>
